import { mediaStoreBaseURL } from 'config/common';
import { Card, CollectionHeader } from 'molecules';

const TrendingCollection = ({ collections = [] }) => (
  <section className='py-7 md:py-10 px-5 md:px-20 md:mb-5 pt-10 mx-10'>
    <CollectionHeader
      {...{
        collections,
        title: 'Trending Collections',
        subTitle: 'Our top picks for the season.',
        buttonText: 'View all',
        buttonUrl: '/collections',
        isOpenInSamePage: true
      }}
    />
    <div className='flex flex flex-row items-center justify-between mt-10'>
      {collections.map(({ id, name, bannerImageUrl, slug }) => (
        <Card
          key={id}
          title={name}
          imgSrc={
            bannerImageUrl ? `${mediaStoreBaseURL}/${bannerImageUrl}` : ''
          }
          linkUrl={`/collections/${slug}`}
        />
      ))}
    </div>
  </section>
);

export default TrendingCollection;
