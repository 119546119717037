import { Card, CollectionHeader } from 'molecules';

const HaflaBlog = ({ posts = [] }) => (
  <section className='py-7 md:py-15.5 px-5 md:px-20 md:mb-5'>
    <CollectionHeader
      {...{
        collections: posts,
        title: 'the hafla blog',
        subTitle: 'Stay updated with the latest news.',
        buttonText: 'View all',
        buttonUrl: '/blog'
      }}
    />
    <div className='flex flex flex-row items-center justify-between mt-10'>
      {posts.map(({ id, yoast_head_json: yoastHeadJson, title, link }) => (
        <Card
          key={id}
          {...{
            title: title?.rendered || '',
            imgSrc: yoastHeadJson?.og_image[0]?.url || '',
            linkUrl: link,
            type: 'blog'
          }}
        />
      ))}
    </div>
  </section>
);

export default HaflaBlog;
