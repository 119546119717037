import { Icon } from 'atoms';
import { staticMediaStoreBaseURL } from 'config/common';

const EventIcons = ({ activeFeaturedEventList }) =>
  activeFeaturedEventList.map((event) => {
    const href = event.isExternal ? event.externalURL : `/events/${event.slug}`;
    return (
      <Icon
        key={event.id}
        href={href}
        title={event.name}
        className='img-center h-30 w-30'
        alt={event.name}
        url={`${staticMediaStoreBaseURL}${event.logoUrl}`}
      />
    );
  });

const FeaturedEvents = ({ featuredEvents }) => {
  const activeFeaturedEventList = featuredEvents.filter(
    ({ isActive }) => isActive
  );
  return (
    <div className='flex flex-row overflow-x-scroll md:justify-center md:flex-col md:overflow-hidden no-scrollbar'>
      <div className='flex flex-nowrap items-start md:space-x-10 w-full mx-auto justify-evenly'>
        <EventIcons {...{ activeFeaturedEventList }} />
      </div>
    </div>
  );
};

export default FeaturedEvents;
