import dynamic from 'next/dynamic';

import { CollectionHeader } from 'molecules';

const VideoCard = dynamic(() => import('molecules/homePage/web/VideoCard'), {
  ssr: false
});

const EventsGlimpses = ({ eventGlimpsesList = [] }) => (
  <section className='py-7 md:py-10 px-5 md:px-20 md:mb-5 mx-10'>
    <CollectionHeader
      {...{
        title: 'A Glimpse into our Events',
        subTitle: 'A tour of events we have executed.',
        isOpenInSamePage: true
      }}
    />
    <div className='flex flex flex-row items-center mt-10'>
      {eventGlimpsesList.map(({ id, title, url, thumbnailUrl }) => (
        <VideoCard
          key={id}
          {...{ title, url, thumbnailUrl }}
        />
      ))}
    </div>
  </section>
);

export default EventsGlimpses;
