import Image from 'next/image';
import Link from 'next/link';
import { Fragment } from 'react';

import { staticMediaStoreBaseURL } from 'config/common';
import featuredCategories from 'data/featured-categories';

export default function FeaturedCategories() {
  const CategoryList = () => (
    <Link href={'/categories/list'}>
      <div className='w-full mb-5'>
        <div className='block w-full cursor-pointer'>
          <div className='rounded-full w-15 xxxs:w-full mx-auto'>
            <Image
              alt='More'
              className='w-full'
              height={100}
              src={`${staticMediaStoreBaseURL}/icons/more.svg`}
              width={100}
            />
          </div>
          <p className='font-Inter font-normal text-11px basic:text-xs leading-11px basic:leading-3 text-nero block text-center mt-2'>
            {' '}
            More{' '}
          </p>
        </div>
      </div>
    </Link>
  );

  const FeaturedCategoriesList = () =>
    featuredCategories.map(({ id, name, href, logoUrl }) => (
      <Link
        key={id}
        href={href}
      >
        <div className='mb-5 w-full cursor-pointer block'>
          <div className='rounded-full w-15 xxxs:w-full mx-auto'>
            <Image
              alt={name}
              className='w-full'
              height={100}
              src={`${staticMediaStoreBaseURL}${logoUrl}`}
              width={100}
            />
          </div>
          <div className='max-w-18 mx-auto block'>
            <p
              className={`font-Inter font-normal text-10px basic:text-xs leading-11px basic:leading-3 text-nero block mt-2 text-center ${
                name === 'Kids Entertainment'
                  ? 'break-all basic:break-normal'
                  : 'break-words basic:break-normal'
              }`}
            >
              {' '}
              {name}{' '}
            </p>
          </div>
        </div>
      </Link>
    ));

  return (
    <Fragment>
      <section id='category-section'>
        {/* #TODO: Remove background to match UI with Web */}
        <div className='pt-10 px-3 xxxs:px-5'>
          <div className='grid grid-cols-4 grid-flow-row grid-rows-2 gap-x-3'>
            <FeaturedCategoriesList />
            <CategoryList />
          </div>
        </div>
      </section>
    </Fragment>
  );
}
