import config from 'config';

import { staticMediaStoreBaseURL } from 'config/common';
import { Card, CollectionHeader } from 'molecules';

const CorporateEvents = ({ eventList }) => (
  <section className='py-7 md:py-10 px-5 md:px-20 md:mb-5 mx-10'>
    <CollectionHeader
      {...{
        eventList,
        title: 'Corporate Events',
        subTitle: 'Book everything you need for your business events.',
        buttonText: 'Consult Event expert',
        buttonUrl: config.corporateEventLink
      }}
    />
    <div className='flex flex flex-row items-center mt-10'>
      {eventList.map(({ id, name, image }) => (
        <Card
          key={id}
          title={name}
          imgSrc={`${staticMediaStoreBaseURL}/events/corporate-event/${image}`}
        />
      ))}
    </div>
  </section>
);

export default CorporateEvents;
