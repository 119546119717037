import Image from 'next/image';
import Link from 'next/link';
import { Fragment, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useScrollData } from 'scroll-data-hook';

import { staticMediaStoreBaseURL } from 'config/common';
import phoneConfig from 'config/phone';
import { getFormattedNumber, PNF } from 'helpers/formatPhoneNumber';
import { captureGTMEventWhatsappClick } from 'lib/gtm';
import { getInitiateWhatsappChatLink } from 'services/user.service';

const { phoneNumbers } = phoneConfig;

const uaeLandlineNumber = getFormattedNumber(
  phoneNumbers.haflaLandline,
  'UAE',
  PNF.E164
);

export default function MobileHomeHeader({ showSearchModal }) {
  const [onScrolling, setOnScrolling] = useState(false);
  const { position } = useScrollData({});

  useEffect(() => {
    if (position.y > 477 && !onScrolling) {
      setOnScrolling(true);
    } else if (position.y < 477 && onScrolling) {
      setOnScrolling(false);
    }
  }, [position.y]);

  const openWhatsappInNewTab = () => {
    captureGTMEventWhatsappClick();
    window.open(
      getInitiateWhatsappChatLink(isMobile),
      '_blank',
      'noopener, noreferrer,nofollow'
    );
  };

  return (
    <Fragment>
      <section
        id='header-section'
        className='pb-12.5'
      >
        <div
          className={`h-12.5 items-center px-5 py-3.5 flex justify-between shadow bg-white ${
            !showSearchModal && onScrolling
              ? 'fixed sticky-header z-120 w-full'
              : 'absolute w-full top-0'
          }`}
        >
          <Link href='/'>
            <a>
              <Image
                alt='Hafla'
                className='block cursor-pointer max-w-17.5 h-6 w-full'
                src={`${staticMediaStoreBaseURL}/logos/hafla_new.svg`}
                height={24}
                width={70}
              />
            </a>
          </Link>

          <div className='flex gap-4'>
            <div className='relative'>
              <a
                id='call-icon'
                href={`tel:${uaeLandlineNumber}`}
              >
                <Image
                  className='cursor-pointer w-full'
                  layout='fixed'
                  width={24}
                  height={24}
                  src={`${staticMediaStoreBaseURL}/icons/call-round-mobile.svg`}
                  alt='Call'
                />
              </a>
            </div>

            <div
              className='relative'
              onClick={openWhatsappInNewTab}
            >
              <Image
                className='cursor-pointer w-full'
                height={26}
                width={26}
                layout='fixed'
                src={`${staticMediaStoreBaseURL}/icons/whatsapp-green.svg`}
                alt='Whatsapp'
              />
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}
