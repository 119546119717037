import { Icon, SectionHeading } from 'atoms';
import { staticMediaStoreBaseURL } from 'config/common';
import featuredCategories from 'data/featured-categories';
import { captureGTMEventBrowseCategoriesClick } from 'lib/gtm';

const FeaturedCategories = () => {
  const RenderCategoryIcon = () =>
    featuredCategories.map(({ id, name, href, logoUrl }) => (
      <Icon
        key={id}
        href={href}
        onClick={() => captureGTMEventBrowseCategoriesClick({ name })}
        title={name}
        className='img-center h-30 w-30'
        alt={name}
        url={`${staticMediaStoreBaseURL}${logoUrl}`}
      />
    ));
  return (
    <div className='relative flex flex-row overflow-x-scroll md:justify-center md:flex-col md:overflow-hidden no-scrollbar mt-15 pb-10'>
      <SectionHeading
        title='browse by category'
        underline
      />
      <div className='flex flex-nowrap space-x-3 justify-between items-start md:justify-center md:space-x-10 mx-auto mt-5 mb-5'>
        <RenderCategoryIcon />
      </div>
    </div>
  );
};

export default FeaturedCategories;
