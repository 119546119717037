module.exports = {
  featuredEvents: [
    {
      id: '594a6219-fb01-461f-9cf6-69aa276843b7',
      isActive: true,
      isExternal: false,
      logoUrl: '/mobile-events/events/wedding-event.svg',
      name: 'Wedding & Engagement',
      slug: 'wedding-events'
    },
    {
      id: 'aec4a6cb-1824-4528-87c6-e8b4ea467e65',
      isActive: true,
      isExternal: false,
      logoUrl: '/mobile-events/events/corporate-event.svg',
      name: 'Corporate Events',
      slug: 'corporate-events'
    },
    {
      externalURL: 'https://book.hafla.com/pages/ramadan-celebrations',
      id: 'dummy',
      isActive: false,
      isExternal: true,
      logoUrl: '/mobile-events/events/ramadan.svg',
      name: 'Ramadan'
    },
    {
      id: '5a2e73b2-6743-41dc-b300-80b0bd08589f',
      isActive: true,
      isExternal: false,
      logoUrl: '/mobile-events/events/social.svg',
      name: 'Social Events',
      slug: 'celebrations',
      slugOld: 'social-events'
    },
    {
      id: 'fbcf9836-8b27-4bcd-88c0-a4d244e0bed8',
      isActive: true,
      isExternal: false,
      logoUrl: '/mobile-events/events/kids_birthday-event.svg',
      name: 'Kids Birthday',
      slug: 'kids-birthday-events',
      slugOld: 'kids-birthday'
    },
    {
      id: '510aa0eb-c6ce-4272-8546-8958ea312d38',
      isActive: false,
      isExternal: false,
      logoUrl: '/events/other-event.svg',
      name: 'Other Celebrations',
      slug: 'celebrations'
    },
    {
      id: '17c7dc7b-eed2-43e3-8806-067c25cf48e7',
      isActive: false,
      isExternal: false,
      logoUrl: '/events/expo_2021-event.svg',
      name: 'Expo 2020',
      slug: 'expo-2020'
    }
  ]
};
